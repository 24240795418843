import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'listGamingStatistics',
            import: () => import('./pages/gaming-statistics/GamingStatistics')
        },
        {
            path: 'listGamingStatisticsStake',
            import: () => import('./pages/gaming-statistics-stake/GamingStatisticsStake')
        },
        {
            path: 'listJoinLeaveChannels',
            import: () => import('./pages/join-leave-channels/JoinLeaveChannels')
        },
    ])
}