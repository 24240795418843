import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        // table theme
        {
            path: 'listTourTableTheme',
            import: () => import('./page/table-theme/listTheme/ListTheme')
        },
        {
            path: 'createTourTableTheme',
            import: () => import('./page/table-theme/createTheme/CreateTheme')
        },
        {
            path: 'editTourTableTheme/:id',
            import: () => import('./EditTheme')
        },
        // card back
        {
            path: 'listTourCardBack',
            import: () => import('./page/card-back/list-card-back/ListCardBack')
        },
        {
            path: 'editTourCardBack/:id',
            import: () => import('./EditCardBack')
        },
        {
            path: 'createTourCardback',
            import: () => import('./page/card-back/create-card-back/CreateCardBack')
        },
        // game play background
        {
            path: 'listTourGamePlayBg',
            import: () => import('./page/game-play-background/list-game-play-background/ListGamePlayBackground')
        },
        {
            path: 'createTourGamePlayBg',
            import: () => import('./page/game-play-background/create-game-play-background/CreateGamePlayBackground')
        },
        {
            path: 'editTourGamePlayBg/:id',
            import: () => import('./EditGamePlayBackground')
        },
    ])
}