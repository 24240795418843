import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        // table theme
        {
            path: 'listTableTheme',
            import: () => import('./page/table-theme/listTheme/ListTheme')
        },
        {
            path: 'createTableTheme',
            import: () => import('./page/table-theme/createTheme/CreateTheme')
        },
        {
            path: 'editTableTheme/:id',
            import: () => import('./EditTheme')
        },
        // emojis
        {
            path: 'listEmojis',
            import: () => import('./page/emojis/listEmojis/ListEmojis')
        },
        {
            path: 'editEmojis/:id',
            import: () => import('./EditEmojis')
        },
        {
            path: 'createEmojis',
            import: () => import('./page/emojis/createEmojis/CreateEmojis')
        },
        // card back
        {
            path: 'listCardBack',
            import: () => import('./page/card-back/list-card-back/ListCardBack')
        },
        {
            path: 'editCardBack/:id',
            import: () => import('./EditCardBack')
        },
        {
            path: 'createCardback',
            import: () => import('./page/card-back/create-card-back/CreateCardBack')
        },
        // player avatar
        {
            path: 'listPlayerAvatar',
            import: () => import('./page/player-avatar/list-player-avatar/ListPlayerAvatar')
        },
        {
            path: 'createPlayerAvatar',
            import: () => import('./page/player-avatar/create-player-avatar/CreatePlayerAvatar')
        },
        {
            path: 'editPlayerAvatar/:id',
            import: () => import('./EditPlayerAvatar')
        },
        // game play background
        {
            path: 'listGamePlayBg',
            import: () => import('./page/game-play-background/list-game-play-background/ListGamePlayBackground')
        },
        {
            path: 'createGamePlayBg',
            import: () => import('./page/game-play-background/create-game-play-background/CreateGamePlayBackground')
        },
        {
            path: 'editGamePlayBg/:id',
            import: () => import('./EditGamePlayBackground')
        },
        // lobby background
        {
            path: 'listLobbyBg',
            import: () => import('./page/lobby-background/list-lobby-background/ListLobbyBackground')
        },
        {
            path: 'createLobbyBg',
            import: () => import('./page/lobby-background/create-lobby-background/CreateLobbyBackground')
        },
        {
            path: 'editLobbyBg/:id',
            import: () => import('./EditLobbyBackground')
        },
        // lobby categories
        {
            path: 'listLobbyCategories',
            import: () => import('./page/lobby-categories/list-lobby-categories/ListLobbyCategories')
        },
        {
            path: 'createLobbyCategories',
            import: () => import('./page/lobby-categories/create-lobby-categories/CreateLobbyCategories')
        },
        {
            path: 'editLobbyCategories/:id',
            import: () => import('./EditLobbyCategories')
        },
    ])
}