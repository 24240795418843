import { RouteObject } from 'react-router';

import { loadable } from '@/components/shared/Loadable';

const DepositLimit = loadable(() => import('./pages/deposit-limit/DepositLimit'));
const WithdrawalLimit = loadable(() => import('./pages/withdrawal-limit/WithdrawalLimit'));
const CashLimit = loadable(() => import('./pages/cash-limit/CashLimit'));
const SelfExclusion = loadable(() => import('./pages/self-exclusion/SelfExclusion'));
export const Router: RouteObject = {
  path: '',
  children: [
    {
      path: 'depositlimit',
      element: <DepositLimit />
    },
    {
      path: 'withdrawalLimit',
      element: <WithdrawalLimit />
    },
    {
      path: 'cashlimit',
      element: <CashLimit />
    },
    {
      path: 'selfExclusion',
      element: <SelfExclusion />
    }
  ]
}