import React, { Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { getBreadcrums, getUniqueKey } from '@/helpers/common'
import { useAppSelector } from '@/store/hooks'

import './BreadcrumbCustomer.scss'
import { Button } from 'antd'

export type BreadcrumbProps = {
    customActions?: React.FC<any>;
}

const BreadcrumbCustomer: React.FC<BreadcrumbProps> = ({ customActions: CustomActions }) => {
    const { pathname } = useLocation()

    const { modules } = useAppSelector(store => store.global)
    const [breadcurms, setBreadcurms] = useState<string[]>([])

    useEffect(() => {
        if (modules && modules.length && pathname && pathname !== '') {
            const data = getBreadcrums(modules, pathname)
            setBreadcurms(data)
        }
    }, [pathname, modules])

    return (
        <Fragment>
            <div
                className='d-flex align-items-center bg-white'
                style={{
                    marginBottom: 20
                }}
            >

                <ol className="breadcrumb text-muted fs-6 fw-bold flex-fill">
                    <li className="breadcrumb-item">
                        <a href="/dashboard">
                            <i className="bi bi-house-door-fill"></i>
                            <span>Home</span>
                        </a>
                        {pathname.split("/")[1] === "playerInfoReportCustomerSupport" ? (<></>) : (<FontAwesomeIcon icon={faAngleRight} />)}
                    </li>

                    {pathname.split("/")[2] === "viewUpdatesList" ?
                        <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management <FontAwesomeIcon icon={faAngleRight} /> Table View & Creation <FontAwesomeIcon icon={faAngleRight} /> Updated history </span>
                        </li> : pathname.split("/")[2] === "edit" && pathname.split("/")[1] !== "leaderboardSet" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management <FontAwesomeIcon icon={faAngleRight} /> Table View & Creation <FontAwesomeIcon icon={faAngleRight} /> Edit Table </span>
                        </li> : pathname.split("/")[2] === "view" && pathname.split("/")[1] !== "leaderboardSet" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management <FontAwesomeIcon icon={faAngleRight} /> Table View & Creation <FontAwesomeIcon icon={faAngleRight} /> View Table </span>
                        </li> : pathname.split("/")[2] === "duplicate" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management <FontAwesomeIcon icon={faAngleRight} /> Table View & Creation <FontAwesomeIcon icon={faAngleRight} /> Duplicate Table </span>
                        </li> : pathname.split("/")[2] === "viewUpdateRecord" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management <FontAwesomeIcon icon={faAngleRight} /> Table View & Creation <FontAwesomeIcon icon={faAngleRight} /> View Changes </span>
                        </li> : pathname.split("/")[1] === "createTable" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management <FontAwesomeIcon icon={faAngleRight} /> Table View & Creation <FontAwesomeIcon icon={faAngleRight} /> Create Table </span>
                        </li> : pathname.split("/")[1] === "findPlayerChartGamesPlayed" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Player Report Management <FontAwesomeIcon icon={faAngleRight} /> player report chart games played </span>
                        </li> : pathname.split("/")[1] === "playerInfoReportCustomerSupport" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span><FontAwesomeIcon icon={faAngleRight} /> Customer Support Management <FontAwesomeIcon icon={faAngleRight} /> Player Info Reports </span>
                        </li> : ""}
                    {pathname.split("/")[1] === "leaderboardSet" && pathname.split("/")[2] === "view" ?
                        <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Leaderboard Set Management <FontAwesomeIcon icon={faAngleRight} /> List Leaderboard Set  <FontAwesomeIcon icon={faAngleRight} /> View Leaderboard Set </span>
                        </li> : pathname.split("/")[1] === "createLeaderboardCategory" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Leaderboard Set Management <FontAwesomeIcon icon={faAngleRight} /> Create Leaderboard Set </span>
                        </li> : pathname.split("/")[1] === "leaderboardSet" && pathname.split("/")[2] === "edit" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Leaderboard Set Management <FontAwesomeIcon icon={faAngleRight} /> List Leaderboard Set  <FontAwesomeIcon icon={faAngleRight} /> Edit Leaderboard Set </span>
                        </li> : ""}
                    {pathname.split("/")[1] === "viewListRoom" ?
                        <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                            <span>Game Management<FontAwesomeIcon icon={faAngleRight} /> List Room <FontAwesomeIcon icon={faAngleRight} /> View Table In Room </span>
                        </li> : ""}
                    
                        {pathname.split("/")[1] === "listTableTheme" ?
                            <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management <FontAwesomeIcon icon={faAngleRight} /> List Table Theme </span>
                            </li> : pathname.split("/")[1] === "createTableTheme" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management  <FontAwesomeIcon icon={faAngleRight} /> Create Table Theme  </span>
                            </li> : pathname.split("/")[1] === "editTableTheme" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management  <FontAwesomeIcon icon={faAngleRight} /> Edit Table Theme  </span>
                            </li> : pathname.split("/")[1] === "createEmojis" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management  <FontAwesomeIcon icon={faAngleRight} /> Create Emojis  </span>
                            </li> : pathname.split("/")[1] === "createCardback" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management  <FontAwesomeIcon icon={faAngleRight} /> Create Card Back  </span>
                            </li> : pathname.split("/")[1] === "createPlayerAvatar" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management  <FontAwesomeIcon icon={faAngleRight} /> Create Player Avatar  </span>
                            </li> : pathname.split("/")[1] === "listPlayerAvatar" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Assets Management  <FontAwesomeIcon icon={faAngleRight} /> List Player Avatar  </span>
                            </li> : pathname.split("/")[1] === "CreateBugsReason" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Reported Bugs Management <FontAwesomeIcon icon={faAngleRight} />  Bug Reason <FontAwesomeIcon icon={faAngleRight} /> Create Bug Reason </span>
                            </li> : pathname.split("/")[1] === "bugReason" ? <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                                <span>Reported Bugs Management <FontAwesomeIcon icon={faAngleRight} />  Bug Reason <FontAwesomeIcon icon={faAngleRight} /> Edit Bug Reason </span>
                            </li> : ""}
                </ol>
                {CustomActions &&
                    <div className='bg-white'>
                        <CustomActions />
                    </div>
                }
            </div>
        </Fragment>
    )
}

export { BreadcrumbCustomer }