import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'playerSegregationConfig',
            import: () => import('./pages/player-segregation/PlayerSegregation')
        },
        {
            path: 'upgradePlayerVipLevel',
            import: () => import('./pages/upgrade-player-vip-level/UpgradePlayerVipLevel')
        }
    ])
}