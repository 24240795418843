import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'listPlayerWageredReport',
            import: () => import('./pages/player-wagered-report/PlayerWageredReport')
        },
        {
            path: 'listPlayerWageredHistory',
            import: () => import('./pages/player-wagered-history/PlayerWageredHistory')
        },
    ])
}