import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { getBreadcrums, getUniqueKey } from '@/helpers/common';
import { useAppSelector } from '@/store/hooks';
import './Breadcrumb.scss';
import { Button } from 'antd';
import { useNavigate } from "react-router";
import { Button as AntButton } from "antd";

export type BreadcrumbProps = {
  customActions?: React.FC<any>;
}

const Breadcrumb: React.FC<BreadcrumbProps & { showCreateButton?: boolean }> = ({ customActions: CustomActions, showCreateButton }) => {
  const { pathname } = useLocation();
  // console.log("🚀 ~ pathname:", pathname.split("/")[1])

  const { modules } = useAppSelector(store => store.global);

  const [breadcurms, setBreadcurms] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (modules && modules.length && pathname && pathname !== '') {
      const data = getBreadcrums(modules, pathname);
      setBreadcurms(data);
    }
  }, [pathname, modules]);

  return (
    <Fragment>
      <div
        className='d-flex align-items-center bg-white'
        style={{
          marginBottom: 20
        }}
      >
        <ol className="breadcrumb text-muted fs-6 fw-bold flex-fill">
          <li className="breadcrumb-item">
            <a href="/dashboard">
              <i className="bi bi-house-door-fill"></i>
              <span>Home</span>
            </a>
            <FontAwesomeIcon icon={faAngleRight} />
          </li>

          {pathname.split("/")[1] === "listTableTheme" ? (
            <li className="breadcrumb-item text-muted">
              <span>Assets Management <FontAwesomeIcon icon={faAngleRight} /> List Table Theme </span>
            </li>
          ) : pathname.split("/")[1] === "listPlayerAvatar" ? (
            <li className="breadcrumb-item text-muted">
              <span>Assets Management <FontAwesomeIcon icon={faAngleRight} /> List Player Avatar </span>
            </li>
          ) : pathname.split("/")[1] === "listPlayerBonus" ? (
            <li className="breadcrumb-item text-muted">
              <span>Player Bonus Report Management <FontAwesomeIcon icon={faAngleRight} /> List Deposit Bonus </span>
            </li>
          ) : pathname.split("/")[1] === "listPlayerWageredReport" ? (
            <li className="breadcrumb-item text-muted">
              <span>Player Wagered Report Management <FontAwesomeIcon icon={faAngleRight} /> Player Wagered Report </span>
            </li>
          ) : pathname.split("/")[1] === "listPlayerWageredHistory" ? (
            <li className="breadcrumb-item text-muted">
              <span>Player Wagered Report Management <FontAwesomeIcon icon={faAngleRight} /> Player Wagered History </span>
            </li>
          ) : pathname.split("/")[1] === "balanceSheetNew" ? (
            <li className="breadcrumb-item text-muted">
              <span>Accounts <FontAwesomeIcon icon={faAngleRight} /> Balance Sheet New </span>
            </li>
          ) : pathname.split("/")[1] === "listLosingBonusReport" ? (
            <li className="breadcrumb-item text-muted">
              <span>Losing Bonus Management <FontAwesomeIcon icon={faAngleRight} /> Losing Bonus Report </span>
            </li>
          ) : (
            breadcurms.map((item, index) => (
              <li className="breadcrumb-item text-muted" key={getUniqueKey()}>
                <span>{item}</span>
                {index < breadcurms.length - 1 && <FontAwesomeIcon icon={faAngleRight} />}
              </li>
            ))
          )}
          
        </ol>

        {showCreateButton && ( // Điều kiện hiển thị nút Create
          <AntButton
            onClick={() => navigate('/CreateBugsReason')}
            style={{
              height: 'auto',
              background: 'var(--info)',
              border: 'none'
            }}
          >
            <span style={{ fontSize: 25, color: 'white' }}>Create</span>
          </AntButton>
        )}

        {CustomActions &&
          <div className='bg-white'>
            <CustomActions />
          </div>
        }
      </div>
    </Fragment>
  )
}

export { Breadcrumb }
