import { RouteObject } from "react-router";

import { routesWithLazy } from "@/helpers/routing";

export const Router: RouteObject = {
  path: "",
  children: routesWithLazy([
    {
      path: "createCategory",
      import: () => import("./pages/category/create-category/CreateCategory"),
    },
    {
      path: "listCategory",
      import: () => import("./pages/category/list-category/ListCategory"),
    },
    {
      path: "editCategory/:id",
      import: () => import("./pages/category/edit-category/Layout"),
    },
    {
      path: "createFAQ",
      import: () => import("./pages/faq/create-faq/CreateFaq"),
    },
    {
      path: "listFAQ",
      import: () => import("./pages/faq/list-faq/ListFAQ"),
    },
    {
      path: "editFAQ/:id",
      import: () => import("./pages/faq/edit-faq/Layout"),
    },
    {
      path: "viewFAQ/:id",
      import: () => import("./pages/faq/view-faq/Layout"),
    },
  ]),
};
