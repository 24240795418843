import { RouteObject } from "react-router";

import { routesWithLazy } from "@/helpers/routing";

export const Router: RouteObject = {
  path: "",
  children: routesWithLazy([
    {
      path: "listTaxLedger",
      import: () => import("./pages/list/List"),
    }
  ]),
};
