import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
  path: '',
  children: routesWithLazy([
    {
      path: 'createRoom',
      import: () => import('./page/create-room/CreateRoom')
    },
    {
      path: 'listRoom',
      import: () => import('./page/list-room/ListRoom')
    },
    {
      path: "editRoom/:id",
      import: () => import('./page/edit-room/EditRoom')
    },
    {
      path: 'viewRoom/:id',
      import: () => import('./page/view-room/ViewRoom')
    },
    {
      path: 'viewListRoom/:id',
      import: () => import('./page/view-list-room/ViewListRoom')
    },
    {
      path: 'duplicateRoom/:id',
      import: () => import('./page/duplicate-room/DuplicateRoom')
    }
  ])
}