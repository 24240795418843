import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'listTdsReport',
            import: () => import('./pages/ListTdsReport/TdsReport')
        },
        {
            path: 'tdsReport/detail/:id',
            import: () => import('./pages/TdsReportDetail/TdsReportDetail')
          }
    ])
}