import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
  path: '',
  children: routesWithLazy([
    {
      path: 'losingBonusConfig',
      import: () => import('./pages/losing-bonus-config/LosingBonusConfig')
    },
    {
      path: 'listLosingBonusReport',
      import: () => import('./pages/losing-bonus-report/LosingBonusReport')
    }
  ])
}