import { createReducer } from '@reduxjs/toolkit';

export interface IState {

}

const initialState: IState = {

}

export const reducer = createReducer(initialState, (builder) => {

});