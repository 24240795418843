import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'listDeposit',
            import: () => import('./pages/deposit/Deposit')
        },
        {
            path: 'listWidthdraw',
            import: () => import('./pages/widthdraw/Widthdraw')
        },
        {
            path: 'listCashGame',
            import: () => import('./pages/cashgame/Cashgame')
        },
        {
            path: 'listTournamentFigures',
            import: () => import('./pages/tournament/Tournament')
        },
        {
            path: 'listLeaderboards',
            import: () => import('./pages/leaderboard/Leaderboard')
        },
        {
            path: 'gameHistory/:referenceNumber',
            import: () => import('./pages/game-history/GameHistory')
        },
        {
            path: 'handPlayed/:playerId/:handId',
            import: () => import('./pages/hand-played/HandPlayed')
        },
        {
            path: 'handPlayed/:playerId',
            import: () => import('./pages/hand-played/HandPlayed')
        },
        {
            path: 'tournamentGameHistory/:referenceNumber',
            import: () => import('./pages/tournament-game-history/TournamentGameHistory')
        },
        {
            path: 'tournamentHandPlayed/:playerId/:handId',
            import: () => import('./pages/tournament-hand-played/TournamentHandPlayed')
        },
        {
            path: 'tournamentHandPlayed/:playerId',
            import: () => import('./pages/tournament-hand-played/TournamentHandPlayed')
        },
        {
            path: 'listLosingBonus',
            import: () => import('./pages/losing-bonus/LosingBonus')
        },
    ])
}