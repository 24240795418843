import { RouteObject } from 'react-router';

import { loadable } from '@/components/shared/Loadable';
import { routesWithLazy } from '@/helpers/routing';
const CreateAccessRule = loadable(() => import('./pages/create-access-rule/CreateAccessRule'));
const ListAccessRule = loadable(() => import('./pages/list-access-rule/ListAccessRule'));
const ViewAccessRule = loadable(() => import('./pages/view-access-rule/ViewAccessRule'));
const EditAccessRule = loadable(() => import('./pages/edit-access-rule/EditAccessRule'));

export const Router: RouteObject = {
    path: '',
    children: [
        {
            path: 'createaccessrule',
            element: <CreateAccessRule />
        },
        {
            path: 'listaccessrules',
            element: <ListAccessRule />
        },
        {
            path: 'viewaccessrule/:id',
            element: <ViewAccessRule />
        },
        {
            path: 'editaccessrule/:id',
            element: <EditAccessRule />
        },
    ]
}