import { RouteObject } from 'react-router';

import { loadable } from '@/components/shared/Loadable';

const ListTable = loadable(() => import('./pages/ListTable'));
const AddPayoutStructure = loadable(() => import('./pages/create-payout-structure/CreatePayoutStructure'));
const EditStructure = loadable(() => import('./pages/edit-payout-structure/EditPayoutStructure'));
const View = loadable(() => import('./pages/payout-structure/ViewPayout'));
const PayoutView = loadable(() => import('./pages/payout-structure/PayoutView'));
const Edit = loadable(() => import('./pages/payout-structure/EditPayout'));
const DuplicateStructure = loadable(() => import('./pages/duplicate-payout-structure/DuplicatePayourStructure'));


export const Router: RouteObject = {
    path: '',
    children: [
        {
            path: 'listPayoutStructure',
            element: <ListTable />
        },
        {
            path: 'createPayoutStructre',
            element: <AddPayoutStructure />
        },
        {
            path: 'editpayoutstructure/:id',
            element: <EditStructure />
        },
        {
            path: 'viewpayout/:id',
            element: <View />
        },
        {
            path: 'payoutView/:id',
            element: <PayoutView />
        },
        {
            path: 'editViewPayout/:id',
            element: <Edit />
        },
        {
            path: 'duplicatepayoutstructure/:id',
            element: <DuplicateStructure />
        },

    ]
}