import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'jackpotRake',
            import: () => import('./page/jackpot-rake/JackpotRake')
        },
        {
            path: 'redistributionOfFund',
            import: () => import('./page/redistribution-of-fund/RedistributionOfFund')
        },
        {
            path: 'jackpotAmountDistribution',
            import: () => import('./page/jackpot-amount-distribution/JackpotAmountDistribution')
        },
        {
            path: 'jackpotCurrentPool',
            import: () => import('./page/jackpot-current-pool/JackpotCurrentPool')
        }
    ])
}