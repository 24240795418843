import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'listRefererEarnedHistorys',
            import: () => import('./pages/referer-earned-history/RefererEarnedHistory')
        },
        {
            path: 'listRefererEarnedReport',
            import: () => import('./pages/refer-earned-report/RefererEarnedReport')
        },
    ])
}