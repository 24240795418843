// export function cleanObject(obj: any) {
//   return Object.keys(obj).reduce((prev, key) => {
//     const val = obj[key];
//     if (val === null || val === undefined || val === "") {
//       delete prev[key];
//     }
//     return prev;
//   }, obj)
// }
export function cleanObject(obj: any) {
  return Object.keys(obj).reduce((prev, key) => {
    const val = obj[key];
    if (val === null || val === undefined || val === "") {
      delete prev[key];
    }
    return prev;
  }, obj)
}

export function convertFieldObjectToNumeric(obj: any) {
  return Object.keys(obj).reduce((prev, key) => {
    const val = obj[key];
    if (val === null || val === undefined || val === "") {
      prev[key] =  val;
    } else if (!isNaN(Number(val))) {
      prev[key] =  Number(val);
    }
    return prev;
  }, obj)
}

export const isEmptyObject = (obj: any) => JSON.stringify(cleanObject(obj)) === '{}'