import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'jackpotRakeHistory',
            import: () => import('./page/jackpot-rake-history/jackpotRakeHistory')
        },
        {
            path: 'jackpotWinningHistory',
            import: () => import('./page/jackpot-winning-history/jackpotWinningHistory')
        },
    ])
}