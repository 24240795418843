import { RouteObject } from 'react-router';

import { routesWithLazy } from '@/helpers/routing';

export const Router: RouteObject = {
    path: '',
    children: routesWithLazy([
        {
            path: 'listBugsReport',
            import: () => import('./pages/bug-report/BugReport')
        },
        {
            path: 'listBugsReason',
            import: () => import('./pages/bug-reason/List/BugReason')
        },
        {
            path: 'CreateBugsReason',
            import: () => import('./pages/bug-reason/create/CreateBugReason')
        },
        {
            path: 'bugReason/:id',
            import: () => import('./pages/bug-reason/edit/EditBugReason')
        }
    ])
}