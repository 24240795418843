import { RouteObject } from "react-router";

import { routesWithLazy } from "@/helpers/routing";

export const Router: RouteObject = {
  path: "",
  children: routesWithLazy([
    // home ads
    {
      path: "createHomeAds",
      import: () => import("./pages/home-ads/create/CreateHomeAds"),
    },
    {
      path: "listHomeAds",
      import: () => import("./pages/home-ads/list/ListHomeAds"),
    },
    {
      path: "editHomeAds/:id",
      import: () => import("./pages/home-ads/edit/Layout"),
    },
    // lobby ads
    {
      path: "createLobbyAds",
      import: () => import("./pages/lobby-ads/create/CreateLobbyAds"),
    },
    {
      path: "listLobbyAds",
      import: () => import("./pages/lobby-ads/list/ListLobbyAds"),
    },
    {
      path: "editLobbyAds/:id",
      import: () => import("./pages/lobby-ads/edit/Layout"),
    },
      // video ads
      {
        path: "createVideoAds",
        import: () => import("./pages/video-ads/create/CreateVideoAds"),
      },
      {
        path: "listVideoAds",
        import: () => import("./pages/video-ads/list/ListVideoAds"),
      },
      {
        path: "editVideoAds/:id",
        import: () => import("./pages/video-ads/edit/Layout"),
      },
  ]),
};
